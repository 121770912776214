function getScriptGTM(gmt_auth) {
  return `(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 
        'gtm.start': new Date().getTime(), 
        event: 'gtm.js' 
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' 
            + i + dl 
            + '${gmt_auth}';
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-M8SC8BX')`;
}

function getNoscriptGTM(gmt_auth) {
  return `<iframe 
        src='https://www.googletagmanager.com/ns.html?id=GTM-M8SC8BX${gmt_auth}'
        height='0' 
        width='0' 
        style='display:none;visibility:hidden'>
    </iframe>`;
}

function getScriptUtag(env) {
  return `${env}.js`;
}

function getScriptUtagSync(env) {
  return `${env}.sync.js`;
}

export function setGTMConfiguration(): void {
  const APP_ENV = process.env.APP_ENV;
  const gtm = " Google Tag Manager ";

  document.head.appendChild(document.createComment(`${gtm}`));
  const scriptGTM = document.createElement("script");
  scriptGTM.textContent = getScriptGTM(APP_ENV.GTM_AUTH);
  document.head.appendChild(scriptGTM);
  document.head.appendChild(document.createComment(` End${gtm}`));

  document.head.appendChild(document.createComment(`${gtm}UTAG `));
  const scriptUtag = document.createElement("script");
  scriptUtag.src = getScriptUtag(APP_ENV.UTAG_URL);
  scriptUtag.type = "text/javascript";
  scriptUtag.async = true;
  document.head.appendChild(scriptUtag);
  document.head.appendChild(document.createComment(` End${gtm}UTAG `));

  document.head.appendChild(document.createComment(`${gtm}UTAG SYNC `));
  const scriptUtagSync = document.createElement("script");
  scriptUtagSync.src = getScriptUtagSync(APP_ENV.UTAG_URL);
  document.head.appendChild(scriptUtagSync);
  document.head.appendChild(document.createComment(` End${gtm}UTAG SYNC `));

  document.body.appendChild(document.createComment(`${gtm}(noscript) `));
  const noscriptGTM = document.createElement("noscript");
  noscriptGTM.innerHTML = getNoscriptGTM(APP_ENV.GTM_AUTH);
  document.body.appendChild(noscriptGTM);
  document.body.appendChild(document.createComment(` End${gtm}(noscript) `));
}
