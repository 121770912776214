import { MOUNTED, navigateToUrl, registerApplication } from "single-spa";
import { setGTMConfiguration } from "../config/conf.google-tag-manager";
import { MicroFrontMap } from "./models";
import { BlockNavigationUtil } from "./utils";
import { FeatureFlagHelper } from "./utils/feature-flag.helper";

class MicroShellState {
  private currentName = "";
  setCurrentName(name: string) {
    this.currentName = name;
  }
  spaEventHandler = (
    event: CustomEvent<{ newAppStatuses: Record<string, string> }>
  ) => {
    for (const key in event.detail.newAppStatuses) {
      if (
        Object.prototype.hasOwnProperty.call(event.detail.newAppStatuses, key)
      ) {
        const status = event.detail.newAppStatuses[key];
        if (status === MOUNTED) {
          createAppDom(this.currentName, key);
        }
      }
    }
  };
}

const state = new MicroShellState();

export async function bootstrap(props) {
  setGTMConfiguration();
  state.setCurrentName(props.name);

  const importsArray: MicroFrontMap[] = process.env.MFE_URLS.imports;

  const importsObject = importsArray.reduce((acc, { name, url }) => {
    acc[name] = url;
    return acc;
  }, {});

  addImportMap({ imports: importsObject });
  importsArray.forEach((micro) => {
    addApplication(micro);
  });

  window.addEventListener(
    "single-spa:before-mount-routing-event",
    state.spaEventHandler
  );
}

export const mount = () => {
  return Promise.resolve().then(async () => {
    const shortFlowMaintenanceFlag = await isMaintenance();

    if (shortFlowMaintenanceFlag) {
      navigateToUrl("/bancadigital/libre-inversion/desborde/mantenimiento");
    } else if (isInitialRoute()) {
      navigateToUrl("/bancadigital/libre-inversion/terminos-y-condiciones");
    }
  });
};

export async function unmount(_props) {
  window.removeEventListener(
    "single-spa:before-mount-routing-event",
    state.spaEventHandler
  );
}

function addImportMap(newMap: { imports: Record<string, string> }) {
  const script = document.createElement("script");
  script.type = "systemjs-importmap";
  script.textContent = JSON.stringify(newMap);
  document.head.appendChild(script);
  (System as any).prepareImport(true);
}

function addApplication(micro: MicroFrontMap) {
  registerApplication(
    micro.name,
    () => System.import(micro.name) as any,
    (location) => {
      const pathnameSegments: string[] = location.pathname.split("/");
      const INDEX_OF_BASE_PATH = process.env.APP_ENV.INDEX_OF_BASE_PATH;
      return pathnameSegments[INDEX_OF_BASE_PATH] == micro.basePath;
    },
    micro?.customProps
  );
}

async function isMaintenance() {
  const configCatClient = new FeatureFlagHelper(
    process.env.APP_ENV.CONFIGCAT_KEY
  );
  return await configCatClient.getFlag("shortflowmaintenance", false);
}

function isInitialRoute() {
  const pathName = window.location.pathname.replace(/\/+$/, "");
  return pathName.endsWith("libre-inversion") ? true : false;
}

function createAppDom(parentName: string, appName: string) {
  const parent = document.getElementById(
    `single-spa-application:${parentName}`
  );

  const app = document.createElement("div");
  app.id = `single-spa-application:${appName}`;
  parent.after(app);

  (System as any).prepareImport(true);
}

BlockNavigationUtil.handlerNavigation();
