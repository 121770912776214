export class BlockNavigationUtil {
  private static listeners: { [key: string]: EventListener } = {};

  public static blockBackNavigation = () => {
    const hash = "#no-back-button";

    // Main routing event listener
    const routingListener = (event: any) => {
      const newUrl = event.detail.newUrl;
      if (
        newUrl.includes("/bancadigital/libre-inversion") &&
        !newUrl.endsWith("/bancadigital")
      ) {
        if (!window.location.hash) {
          window.location.hash = hash;
        }

        if (!BlockNavigationUtil.listeners.hashchange) {
          const hashChangeListener = (e: HashChangeEvent) => {
            if (window.location.hash !== hash) {
              window.location.hash = hash;
            }
          };
          window.addEventListener("hashchange", hashChangeListener);
          BlockNavigationUtil.listeners.hashchange = hashChangeListener;
        }

        if (!BlockNavigationUtil.listeners.beforeunload) {
          const beforeUnloadListener = (e: BeforeUnloadEvent) => {
            e.preventDefault();
          };
          window.addEventListener("beforeunload", beforeUnloadListener);
          BlockNavigationUtil.listeners.beforeunload = beforeUnloadListener;
        }
      } else {
        BlockNavigationUtil.removeListeners();
      }
    };

    window.addEventListener("single-spa:before-routing-event", routingListener);
    BlockNavigationUtil.listeners.routing = routingListener;
  };

  public static handlerNavigation = () => {
    if (!BlockNavigationUtil.listeners.popstate) {
      const popStateHandler = (e) => {
        if ("singleSpaTrigger" in e) {
          return;
        } else {
          this.removeListeners();
          history.replaceState(null, "", "/bancadigital");
        }
      };
      window.addEventListener("popstate", popStateHandler);
      BlockNavigationUtil.listeners.popstate = popStateHandler;
    }

    if (!BlockNavigationUtil.listeners.routing) {
      const beforeRoutingEventSPA = (event: any) => {
        const detail = event.detail;
        if (detail.newUrl === detail.oldUrl && detail.totalAppChanges === 1) {
          this.removeListeners();
          history.replaceState(null, "", "/bancadigital");
        }
      };
      window.addEventListener(
        "single-spa:before-routing-event",
        beforeRoutingEventSPA
      );
      BlockNavigationUtil.listeners.routing = beforeRoutingEventSPA;
    }
  };

  private static removeListeners() {
    Object.keys(BlockNavigationUtil.listeners).forEach((key) => {
      const listener = BlockNavigationUtil.listeners[key];
      if (listener) {
        switch (key) {
          case "popstate":
            window.removeEventListener("popstate", listener);
            break;
          case "routing":
            window.removeEventListener(
              "single-spa:before-routing-event",
              listener
            );
            break;
          case "hashchange":
            window.removeEventListener("hashchange", listener);
            break;
          case "beforeunload":
            window.removeEventListener("beforeunload", listener);
            break;
        }
        delete BlockNavigationUtil.listeners[key];
      }
    });
  }
}
