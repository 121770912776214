export class HistoryInterceptorUtil {
  private static historyStack: string[] = [];

  public static setupHistoryInterception(): void {
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = function (...args) {
      originalPushState.apply(this, args);
      HistoryInterceptorUtil.updateHistoryStack();
    };

    history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      HistoryInterceptorUtil.updateHistoryStack();
    };

    window.addEventListener("popstate", (event) => {
      if (HistoryInterceptorUtil.shouldPreventReturn()) {
        history.forward();
      }
    });

    // Intercepta eventos de enrutamiento de single-spa
    window.addEventListener("single-spa:before-routing-event", (event: any) => {
      const detail = event.detail;
      const currentUrl = location.href;

      if (window.location.hash) {
        window.location.hash = "";
      }

      if (
        detail.newUrl.includes(currentUrl) &&
        detail.oldUrl.includes(currentUrl)
      ) {
        detail.cancelNavigation();
      }
    });

    // Intercepta el evento beforeunload para prevenir recarga
    window.onbeforeunload = (e) => {
      if (e) {
        e.returnValue = "Se perderán todos los datos que no hayas guardado.";
      }
    };
  }

  /**
   * Actualiza el historial de la pila con la URL actual.
   */
  private static updateHistoryStack(): void {
    const currentUrl = location.href;
    if (!HistoryInterceptorUtil.historyStack.includes(currentUrl)) {
      HistoryInterceptorUtil.historyStack.push(currentUrl);
    }
  }

  /**
   * Determina si se debe prevenir un retorno en el historial.
   * @returns {boolean} Verdadero si se debe prevenir el retorno.
   */
  private static shouldPreventReturn(): boolean {
    const currentUrl = location.href;
    if (
      HistoryInterceptorUtil.historyStack.includes(currentUrl) ||
      HistoryInterceptorUtil.historyStack.length === 1
    ) {
      return true;
    }
    return false;
  }
}
