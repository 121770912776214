import * as configCat from "configcat-js";

export class FeatureFlagHelper {
  private logger = configCat.createConsoleLogger(configCat.LogLevel.Error);
  private client: configCat.IConfigCatClient;

  /**
   * Initializes the ConfigCat client using the provided SDK ID.
   *
   * To set the SDK ID for the new client, intance the class.
   *
   * Example of providing the `CONFIG_CAT_SDK_ID`:
   * ```
   * const configCatInstance = new FeatureFlagHelper('sdk-test-id');
   * ```
   *
   * @param configCatSdkIds The SDK ID used to initialize the ConfigCat client.
   */
  constructor(configCatSdkId: string) {
    this.initializeClients(configCatSdkId);
  }

  private async initializeClients(configCatSdkIds: string): Promise<void> {
    this.client = configCat.getClient(
      configCatSdkIds,
      configCat.PollingMode.ManualPoll,
      {
        logger: this.logger,
      }
    );
  }

  /**
   * Asynchronously retrieves the value of a feature flag.
   *
   * This method fetches the value of a specified feature flag using the ConfigCat client.
   * It can optionally consider user-specific targeting if a user object is provided.
   *
   * @param {string} flagName - The name of the feature flag to retrieve.
   * @param {boolean} defaultValue - The default value to return if the flag value cannot be determined.
   * @param {configCat.User} [user] - An optional user object for targeting specific users.
   * @returns {Promise<boolean>} A promise that resolves to the boolean value of the feature flag.
   *
   * @example
   * // Retrieve a feature flag value with user targeting
   * const isFeatureEnabled = await getFlag('newFeature', false, user);
   *
   * @example
   * // Retrieve a feature flag value without user targeting
   * const isFeatureEnabled = await getFlag('newFeature', false);
   */
  public async getFlag(
    flagName: string,
    defaultValue: boolean,
    user?: configCat.User
  ): Promise<boolean> {
    await this.client.forceRefreshAsync();

    let flagValue = false;
    if (user) {
      flagValue = await this.client.getValueAsync(flagName, defaultValue, user);
    } else {
      flagValue = await this.client.getValueAsync(flagName, defaultValue);
    }
    return flagValue;
  }
}
